import currencyData from "./Common-Currency.json"
export function randomString(length = 7) {
  const possibleChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
  let text = "";

  for (let i = 0; i < length; i++)
    text += possibleChars.charAt(
      Math.floor(Math.random() * possibleChars.length)
    );

  return text;
}

export function currencyFormat(num, symbol = "", isEur = false) {

  if (isEur) {
    return (
      num
      .toFixed(2) // always two decimal digits
      .replace('.', ',') // replace decimal point character with ,
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + ' €'
    ) // use . as a separator
  }

  return (symbol ? symbol+" " : "") + (
    num
    .toFixed(2) // always two decimal digits
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  ) // use . as a separator
  
}

export function breadcrumbPayeeView(currency){
  const title = {
    "gbp": "GBP Payment in UK",
    "eur": "EUR Payment (SEPA)",
    "thb": "THB Payment (Thailand)",
    "swt": "Across the world same currency (SWIFT)",
    "usd": "USD Fed wire (USA only)",
    "localatw": "Local Payment Across the world",
    "swiftatw": "SWIFT Payment Across the world"
  }

  return title[currency] || ""
}

function getRandomColor() {
  var letters = '0123456789ABCDEF';
  var color = '#';
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export function currencyColorGenerater(){

  var cssColor = ""
  var html = ""
  Object.keys(currencyData).forEach(key => {
    var randomColor = getRandomColor();
    cssColor += `.currency-color-${key.toLocaleLowerCase()}{ color : ${randomColor} !important; }\n\r`
    html += `<div style="background-color: ${randomColor}; width:2rem; height: 2rem;"></div>`
  })
}

export function getCurrencyDetail(){
  return currencyData
}