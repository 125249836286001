const Model = {
  form: () => {
    return {
      "name": "",
      "fname": "",
      "lname": "",
      "type": "",
      "currency": "",
      "bankname": "",
      "accountnumber": "",
      "sortcode": "",
      "iban": "",
      "bic": "",
      "fileCount": 0,
      "flagswiftfx": "",
      "reference": "",
      "indentify": {},
      "phonecode": "",
      "phonenumber": "",
      "email": "",
      "idempotency": "",
      "company": {
        "region": "",
        "countrycode": "",
        "zipcode": "",
        "addressnumber": "",
        "refinement": "",
        "street": "",
        "city": "",
        "regulateFinancial": true,
        "riskBusiness": false,
        "registrationNumber": "",
        "approvedate": "",
        "email": "",
        "natureofbusiness": "",
        "webSite": "",
        "name": "",
        "tradingName": "",
        "telephone": "",
        "typeOfBusiness": "",
        "tradingAddress": {
          "region": "",
          "countrycode": "",
          "zipcode": "",
          "addressnumber": "",
          "refinement": "",
          "street": "",
          "city": ""
        }
      },
      "director": {
        "position": "",
        "identifyNumber": "",
        "identifyType": "",
        "identifyExpire": "",
        "email": "",
        "name": "",
        "telephone": "",
        "dateOfBirth": "",
        "nationality": "",
        "residentCountry": "",
        "region": "",
        "countrycode": "",
        "zipcode": "",
        "addressnumber": "",
        "refinement": "",
        "street": "",
        "city": ""
      },
      "personal": {
        "approvedate": "",
        "identifyNumber": "",
        "identifyType": "",
        "identifyExpire": "",
        "email": "",
        "name": "",
        "telephone": "",
        "dateOfBirth": "",
        "nationality": "",
        "residentCountry": "",
        "region": "",
        "countrycode": "",
        "zipcode": "",
        "addressnumber": "",
        "refinement": "",
        "street": "",
        "city": ""
      }
    }
  },

  rule: {
    "name": [{
      required: true,
      message: "Please enter Account name",
      trigger: "blur",
    }, ],
    "fname": [{
      required: true,
      message: "Please enter First name",
      trigger: "blur",
    }, ],
    "lname": [{
      required: true,
      message: "Please enter Last name",
      trigger: "blur",
    }, ],
    "bankname": [{
      required: true,
      message: "Please enter bankname",
      trigger: "blur",
    }, ],
    "accountnumber": [{
      required: true,
      message: "Please enter Account Number",
      trigger: "blur",
    }, ],
    "sortcode": [{
      required: true,
      message: "Please enter Sortcode",
      trigger: "blur",
    }, ],
    "iban": [{
      required: true,
      message: "Please enter IBAN",
      trigger: "blur",
    }, ],
    "bic": [{
      required: true,
      message: "Please enter BIC",
      trigger: "blur",
    }, ],
    "reference": [{required: true, message: ' Reference must put 6-18 character ',min: 6, max: 18, trigger: ['blur', 'change'] }],
    "email": [{ type: 'email', message: 'Please input correct email address', trigger: ['blur', 'change'] }],
  

    "company": {
      "tradingAddress": {
        "region": [{
          required: true,
          message: "Please enter Region",
          trigger: "blur",
        }, ],
        "countrycode": [{
          required: true,
          message: "Please enter Country code",
          trigger: "blur",
        }, ],
        "zipcode": [{
          required: true,
          message: "Please enter Zipcode",
          trigger: "blur",
        }, ],
        "addressnumber": [{
          required: true,
          message: "Please enter Address number",
          trigger: "blur",
        }, ],
        "street": [{
          required: true,
          message: "Please enter Sreet",
          trigger: "blur",
        }, ],
        "city": [{
          required: true,
          message: "Please enter City",
          trigger: "blur",
        }, ],
        "email": [{ type: 'email', message: 'Please input correct email address', trigger: ['blur', 'change'] }],
      }
    },
    "director": {

      "name": [{
        required: true,
        message: "Please enter Director Name",
        trigger: "blur",
      }, ],
      "email": [{ type: 'email', message: 'Please input correct email address', trigger: ['blur', 'change'] }],

    },
    "personal": {
      "region": [{
        required: true,
        message: "Please enter Region",
        trigger: "blur",
      }, ],
      "countrycode": [{
        required: true,
        message: "Please enter Country code",
        trigger: "blur",
      }, ],
      "zipcode": [{
        required: true,
        message: "Please enter Zipcode",
        trigger: "blur",
      }, ],
      "addressnumber": [{
        required: true,
        message: "Please enter Address number",
        trigger: "blur",
      }, ],
      "email": [{ type: 'email', message: 'Please input correct email address', trigger: ['blur', 'change'] }],
      "street": [{
        required: true,
        message: "Please enter Sreet",
        trigger: "blur",
      }, ],
      "city": [{
        required: true,
        message: "Please enter City",
        trigger: "blur",
      }, ]
    }
  }

}

export default Model