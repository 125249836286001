<template>
  <el-form-item label="Bank name" prop="bankname">
    <el-image :style="`width: 40px; vertical-align: middle; margin-right: 10px;`"
      :src="`/bank-logo/uk/${bankLogo}`" fit="contain">
      <div slot="error" class="el-image__error">Select</div>
    </el-image>
    <el-select v-model="bankName" prop="bankname" placeholder="Select" class="form-control-alternative"
      @change="bankSelect">
      <el-option v-for="bank in bankList" :key="bank.name" :label="bank.name" :value="bank.name">
        <el-image :style="`vertical-align: middle;`" :src="`/bank-logo/uk/${bank.logo}`"
          fit="contain">
        </el-image>
        <span style="margin-left: 10px; font-size: 14px">{{
          bank.name
        }}</span>
      </el-option>
    </el-select>
  </el-form-item>
</template>

<script>
import Utils from "@/utils/";
import PayeeApi from "@/api/payee";
export default {
  props: {
    value: {
      type: String,
      default: "",
    },
  },

  data() { 
    return {
      bankList: [],
      bankName: "",
      bankLogo: "",
    }
  },
  methods: {
    getBankName() {
      PayeeApi.getBankList("GBP").then(({ result, data }) => {
        if (result) {
          this.bankList = data;
        }
      });
    },
    bankSelect(value) {
      this.bankColor = 'ffffff'
      this.bankLogo = ''
      let result = Utils.findObject(
        this.bankList,
        value,
        "name"
      );

      if (result) {
        // this.bankColor = result.color
        this.bankLogo = result.logo
        // this.payeeForm.bankname = value.split(' - ')[0] || ""
        // this.payeeForm.bankaddress = result.swiftcode
        this.$emit("onChange", {name: value});
      }
    },
    setBank(name) {
      setTimeout(() => { 
      this.bankColor = 'ffffff'
      this.bankLogo = ''
      let result = Utils.findObject(
        this.bankList,
        name,
        "name"
      );

      //console.log(result)

      if (result) {
        this.bankLogo = result.logo
        this.bankName = result.name
      }}, 400)
    },
  },
  created() { 
    this.getBankName();
  }
}
</script>